<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <div class="user-card">
          <div class="avatar">
            <img :src="imgDataUrl">
            <a
              class="btn"
              v-if="isLoggedIn && currentUser && currentUser.id == user.id"
              @click="avatarToggleShow"
            >
              {{ $t('USERS.change_avatar') }}
            </a>
          </div>
          <div class="user-info">
            <h1>{{user.display_name || $t('UTILITY.unnamed') }}</h1>
          </div>
        </div>

        <div class="profile-edit" v-if="currentUser && currentUser.id == user.id">
          <h3>{{ $t('USERS.edit_title') }}</h3>

          <b-form @submit.stop.prevent="onSubmit">
            <b-form-group :label="$t('USERS.form.name')">
              <b-form-input type="text" v-model="user.name" required :placeholder="$t('USERS.form.name_placeholder')"></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('USERS.form.nick')">
              <b-form-input
                type="text"
                v-model="user.nickname"
                required
                :placeholder="$t('USERS.form.nick_placeholder')"
              ></b-form-input>
            </b-form-group>

            <b-alert show v-if="currentUser.steamid64 && currentUser.steam_url" variant="dark">
              {{ $t('USERS.steam_connected') }}
            </b-alert>
            <b-btn v-else @click="connectSteam">
              {{ $t('USERS.steam_connect') }}
            </b-btn>

            <b-form-group :label="$t('USERS.form.were_found_us')">
              <b-form-input
                type="text"
                v-model="user.were_found_us"
                required
                :placeholder="$t('USERS.form.were_found_us_placeholder')"
              ></b-form-input>
            </b-form-group>

            <b-form-select required v-model="user.profile_extra_a" class="mb-3">
              <option :value="null">{{ $t('USERS.form.profile_extra_a') }}</option>
              <option value="usando_solo">{{ $t('USERS.form.alone') }}</option>
              <option value="usando_time">{{ $t('USERS.form.with_team') }}</option>
            </b-form-select>

            <b-button type="submit" variant="primary">
              {{ $t('USERS.form.submit') }}
            </b-button>
          </b-form>
        </div>
      </div>
    </div>
    <my-upload
      field="user[avatar]"
      v-model="avatarShow"
      method="PATCH"
      :width="500"
      :height="500"
      langType="pt-br"
      noCircle
      @crop-success="avatarCropSuccess"
      @crop-upload-success="avatarCropUploadSuccess"
      @crop-upload-fail="avatarCropUploadFail"
      :url="avatarUploadUrl"
      :headers="avatarHeaders"
      img-format="jpg"
    ></my-upload>

    <b-modal v-model="showOnboarding"
      ref="onboardingModal"
      :title="$t('ONBOARDING.title')"
      :ok-only="true">
      <div class="onboarding-content">
        <p>{{ $t('ONBOARDING.line1') }}</p>
        <p>{{ $t('ONBOARDING.line2') }}</p>
        <ul>
          <li v-html="$t('ONBOARDING.line3')">
          </li>
          <li>
            {{ $t('ONBOARDING.line4') }}
          </li>
        </ul>
        <p>{{ $t('ONBOARDING.line5') }}<br>
        <strong>@fej</strong></p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import myUpload from "vue-image-crop-upload";
export default {
  name: "UserEdit",
  computed: {},
  data() {
    return {
      user: {
        name: "",
        nickname: "",
        display_name: "",
        were_found_us: "",
        profile_extra_a: "",
        steam_url: ""
      },
      showOnboarding: false,
      imgDataUrl: "",
      avatarShow: false,
      avatarHeaders: {
        Authorization: localStorage.getItem("token")
      },
      avatarUploadUrl: `${
        window.location.hostname == "fightback.club"
          ? "https://api.fightback.club"
          : "http://localhost:5000"
      }/users/current_user.json`
    };
  },
  computed: {
  },
  methods: {
    initialize() {
      this.$http.get(`users/${this.$route.params.id}.json`).then(response => {
        this.user = response.data;
        this.imgDataUrl = response.data.avatar.url;
      });

      var urlParams = new URLSearchParams(window.location.search);
      if(urlParams.has('connected')) {
        var self = this;
        this.$http.get(`users/${self.currentUser.id}.json`).then(response => {
          self.$store.dispatch("updateUserProfile", response.data);
        });
      }

      if(urlParams.has('onboarding'))
        this.showOnboarding = true
    },

    connectSteam() {
      window.location.href = `${this.$apiURL}/auth/steam?userid=${this.$route.params.id}`;
    },

    // this.imgDataUrl = response.data.avatar.url
    avatarToggleShow() {
      this.avatarShow = !this.avatarShow;
    },
    avatarCropSuccess(imgDataUrl, field) {
      this.imgDataUrl = imgDataUrl;
    },
    avatarCropUploadSuccess(jsonData, field) {
      // this.imgDataUrl = jsonData.avatar.url
      this.$notify({
        group: "all",
        title: "Avatar alterado com sucesso"
      });

      this.$store.dispatch("updateUserProfile", jsonData);
    },
    avatarCropUploadFail(status, field) {
      this.imgDataUrl = this.user.avatar.url;
    },

    onSubmit() {
      this.$http
        .patch(`users/current_user.json`, { user: this.user })
        .then(response => {
          this.initialize();
          this.$notify({
            group: "all",
            title: "Perfil atualizado"
          });

          this.$store.dispatch("updateUserProfile", response.data);
          this.$router.push("/");
        });
    }
  },
  mounted() {
    this.initialize();
  },
  watch: {
    "$route.params.id": function(id) {
      this.initialize();
    },
    "user.nickname": function(nickname) {
      this.user.display_name = nickname;
    }
  },
  components: {
    "my-upload": myUpload
  }
};
// Header
</script>

<style lang="sass" scoped>
  .user-card
    height: 180px
    display: flex
    border-radius: 4px
    overflow: hidden
    background: #f1f1f1
    margin: 20px 0
    .avatar
      width: 128px
      height: 128px
      overflow: hidden
      border-radius: 250%
      position: relative
      margin: 30px 30px 0 30px
      border: 3px solid #ccc
      img
        width: 100%
        height: 100%
      a
        display: none
        position: absolute
        top: 0
        left: 0
        background: rgba(#ED2024, .5)
        color: white
        width: 100%
        height: 100%
        justify-content: center
        align-items: center

      &:hover
        a
          display: flex
    .user-info
      display: flex
      align-items: flex-end
      padding-bottom: 20px
      flex: 1

</style>
